.forgot-password-container{
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgot-container {
    border: 2px solid #FDF8F5;
    border-radius: 11.421px;
    height: 45vh;
    width: 80vh;
    margin-top: -9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.3rem;
    
}

.forgot-container h1{
    color: #FDF8F5;
    font-size: 1.5rem;
    text-align: center;
    padding: 5%;
    padding-bottom: 6%;
}
.forgot-input{
    width: 70%;
    background-color: black;
    border-color: #FDF8F5;
    border-radius:  3.999px;
    padding: 8px 12px 8px 12px;
    color:#FDF8F5;
}

.password-field-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    width: 100%;
    margin-left: 25%;
}

.password-label {
    color: #FDF8F5;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 5px; 
}

.forgot-container-button {
    width: 100%;
    padding: 15px;
    margin-top: auto;
    background-color: #EBFF08;
    color: black;
    font-size: 18px;
    border: none;
    cursor: pointer;
    border-radius: 7.421px;;
}

.password-field-container button{
    position: relative;
    top: -14%;
    left: 68%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
}

.show-password-btn img {
    width: 90%;
    height: 90%;
}
