header {
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0 5%;
}


.container-logo {
    height: 100%;
    display: flex;
    align-items: center;
}

.container-logo > img {
    width: 100px;
}

.container-options ul {
    display: flex;
    gap: 12px; 
    font-weight: 700;
    color: #FDF8F5;
    align-items: center;
}

.container-options li {
    transition: 0.3s;
    font-size: 10px;
}

.container-options a {
    font-size: 10px;
}

.container-options .header-active-link:hover {
    color: #ccc;
}

.container-options .header-active-link:active {
    color: #f37dd2;
}

@media screen and (min-width: 32em) {
    .container-logo > img {
        width: 150px;
    }

    .container-options ul {
        gap: 4rem
    }

    .container-options li {
        font-size: 14px;
    }

    .container-options a {
        font-size: 14px;
    }
}