.subscribeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    box-sizing: border-box;
    padding-block: 24px;
    gap: 32px;
    width: 100%;
    height: 100%;
    min-height: 85vh;
    
}

.subscribeContainer *{
    box-sizing: border-box;
}

.subscribe-page{
    background-color: black;
    position: relative;
}

.button-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: auto;
    margin-bottom: auto;
}

.img-sub{
    display: inline-block;
    width: 220px; 
    height: 300px;
    overflow: hidden;
}

.img-sub img{
    width: 220px;
    height: 300px; 
}

.Subscribeform {
    position: relative;
    display: inline-block;
    width: 480px;
    box-sizing: border-box;
    padding: 12px;
}

.Subscribeform h1{
    text-align: center;
    white-space: nowrap;
    margin-bottom: 8px;
    margin-top: 0;
    color: #ffffff;
    font-weight: bold;
    font-size: 15px;
}

.Subscribeform h2{
    font-size: 14px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
    color: #ffffff;
    font-weight: lighter;
}

.container-inscricao{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    gap: 12px;
    width: 100%;
}

.Subscribeform label{
    font-weight: lighter;
    color: #ffffff;
    font-size: 12px;
    font-family: Inter, serif;
    padding-right: 5px;
    display: block;
    font-weight: bold;
    flex-wrap: nowrap;
}


.Subscribeform input{
    background-color: black;
    border-color: #ffffff;
    border-radius: 5px;
    color: #ffffff;
    width: 200px;
    padding-inline: 6px;
    padding-block: 4px;
}

.Subscribeform select{
    background-color: black;
    border-color: #ffffff;
    border-radius: 5px;
    border-width: 2px;
    color: #ffffff;
    width: 200px;
    padding: 1.5%;
}

option {
    background-color: white;
    color: black; 
}

.email-container {
    display: flex;
    flex-direction: column;
    gap: 5px
}

.Subscribeform input[type = "checkbox"]{
    width: 2%;
    padding: 0%;
}

.checkbox{
    margin-top: 72px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.containerFinal{
    display: flex;
    margin-left: auto;
    padding-top: 3%;

    
}

.containerFinal p{
    font-size: 10px;
    font-weight: lighter;
    color: #ffffff;
    font-size: 12px;
    font-family: Inter, serif;
    text-decoration: underline;  
    margin-top: auto; 
}

.redirecting{
    height: 88vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white
}

.divLembra{
    display: inline-block;
    text-align: center;
    align-items: center;
    align-content: center;
}

.introduction {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.filled-button {
    background-color: #EBFF08;
    color: #000000;
    padding: 6px 24px;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    font-weight: 700;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox a{
    text-decoration: underline;
}

.subs-error {
    display: flex;
    width: 100%;
    justify-content: center;
    color: red;
    font-weight: bold;
    padding: 10px 0;
}

.register-successful-title {
    font-size: 20px;
    text-align: center;
}

.register-successful-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.register-successful-highlight {
    color: #F102AE;
}

.register-successful-return-message {
    font-size: 20px;
    text-align: center;
}

.inter {
    font-family: Inter, serif;
}

.bold {
    font-weight: bold;
}

.blue {
    color: #460FE1;
}

.error-info {
    position: absolute;
    color: red;
    font-size: 12px;
    margin: 0;
}


@media screen and (max-width: 32em) {
    .Subscribeform {
        width: 100%;
    }
}

.custom-checkbox-2 {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .custom-checkbox-2 input {
    appearance: none; /* Remove os estilos padrão do navegador */
    -webkit-appearance: none; /* Compatibilidade com WebKit */
    width: 12px !important; /* Largura do checkbox */
    height: 12px !important; /* Altura do checkbox */
    border: 2px solid #ccc; /* Borda inicial */
    border-radius: 100% !important; /* Faz com que seja redondo */
    background: transparent; /* Fundo transparente */
    outline: none; /* Remove o contorno ao focar */
    cursor: pointer;
    display: inline-block;
    padding: 6px 6px !important;
    vertical-align: middle;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    margin-right: 6px; /* Espaço entre o círculo e o texto */
  }
  
  .custom-checkbox-2 input:checked {
    background: #ebff08; /* Fundo amarelo ao ser marcado */

  }
  
  .custom-checkbox-2 span {
    font-size: 14px; /* Tamanho do texto */
    color: #333; /* Cor do texto */
    line-height: 1.5;
  }

@media screen and (min-width: 32em) {
    .subscribe-footer {
        font-size: 16px;
    }
}

@media screen and (max-width: 52em) {
    .subscribeContainer {
        flex-direction: column;
    }
}

@media screen and (min-width: 52em) {
    .subscribeContainer {
        flex-direction: row;
    }
    .Subscribeform input{
        width: 200px;
    }
    .container-inscricao{
        width: 100%;
    }

    .Subscribeform {
        display: block;
        width: 480px;
        box-sizing: border-box;
        padding: 12px;
    }
}

@media screen and (max-width: 700px) {   
    .img-sub {
        margin: 0 auto; 
        width: 220px; 
        height: 300px;
        display: flex; 
        justify-content: center;
    }

    .img-sub img{
        width: 220px; 
        height: 300px;
    }

    .Subscribeform input{
        width: 100%;
    }

    .Subscribeform select{
        width: 100%;
    }

    .container-inscricao{
        flex-direction: row;
        width: auto;
        max-width: 100%;
    }

    .container-inscricao div{
        width: 160px;
    }
    
    .containerFinal{
        height: 9vh;
        justify-content: space-evenly;
    }
    
}
