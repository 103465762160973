.selected-filters {
    display: flex;
    gap: 8px;
    margin-top: 16px;
    flex-wrap: wrap;
}

.filter-tag {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    padding: 8px 8px;
    font-size: 14px;
    font-weight: bold;
    color: #000;
}

.filter-tag .remove-btn {
    background: none;
    border: none;
    font-weight: bold;
    margin-left: 8px;
    cursor: pointer;
    border-radius: 4px;
    padding: 0 2px;
}
