.schedule-container {
    background: #000000;
    position: relative;
}

.schedule-header {
    margin: auto;
    height: 300px;
    position: relative;
}

.schedule-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 60%;
    width: 100%;
}

.schedule-title {
    color: #FDF8F5;
    font-size: 40px;
    line-height: 54px;
    font-weight: 800;
    text-align: center;
    margin: 0px;
}

.content-schedule-text {
    width: 90%;
}

.content-schedule-text h2 {
    color: #EBFF08;
    font-size: 30px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    margin-bottom: 1rem;
}

.content-schedule-text .brief-description {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
    font-size: 14px;
    color: white;
    margin-top: 2rem;
}

.schedule-description-container {
    max-width: 85%;
    color: white;
    font-size: 11px;
}

.schedule-description-text {
    text-align: center;
}

.schedule-description-container ol {
    border: 2px solid #460FE1;
    padding-block: 8px;
    padding-inline: 12px 8px;
    border-radius: 10px;
}

.schedule-description-container  li {
    margin-left: 28px;
    margin-top: 4px;
    list-style-type: auto;
}

.schedule-description-postscriptum {
    font-size: 10px;
    font-weight: 300;
    font-style: italic;
}

.schedule-items-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.schedule-items-container-carousel-container {
    position: sticky;
    overflow: hidden;
    width: 100px;
    top: 160px;
    height: 500px;
}

.schedule-items-container-carousel {
    cursor: pointer;
    transition: ease-out 0.3s;
    background: rgb(18,18,18);
    background: linear-gradient(180deg, rgba(18,18,18,0) 0%, rgba(18,18,18,1) 50%, rgba(18,18,18,0) 100%);
}

.color-transition {
    position: absolute;
    width: 100%;
    height: 17px;
    top: 0px;
    left:0;
    background: linear-gradient(to right, rgba(235,255,8,1) 0%, rgba(70,15,225,1) 50%, rgba(241,2,174,1) 100%);
}

.footer-squares {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -5rem;
}

.squares-image{
    position: relative;
    width: 47.9%;
    height: 175px;
    bottom: 0px;
    padding: 2% 0;
}

.buttons-control-schedule {
    display: flex;
    justify-content: center;
    gap: 40px;
    align-items: center;
    flex-direction: column;
  }

  .button-schedule {
    padding: 1.3rem 2.8rem;
    color: black;
    font-weight: 1000;
    border-radius: 25px;
    z-index: 10;
}

  .button-inscrevase {
    border: 2px solid #EBFF08;
    background-color: #EBFF08;
    transition: 0.3s;
}

.button-inscrevase:disabled {
    background-color: #cecece;
    border: 2px solid #cecece;
    color: #000000;
    opacity: 0.5;
}

.button-inscrevase:disabled:hover {
    background-color: #cecece;
    border: 2px solid #cecece;
    color: #000000;
}

.button-inscrevase:hover {
    background-color: black;
    border: 2px solid #EBFF08;
    color: #EBFF08;
}

.button-inscrevase:active {
    background-color: #cccccc;
    border: 2px solid #cccccc;
}

.button-cronograma {
  background-color: transparent;
  border: 2px solid #D9D9D9;
  transition: 0.3s;
  color: #D9D9D9;
}

.button-cronograma:hover {
  background-color: #EBFF08;
  border: 2px solid #000000;
  color: #000000;
}


.button-cronograma:active {
  background-color: #3d3d3d;
}

a{
    z-index: 10;
}


@media screen and (min-width: 40em) {

    .schedule-items-container {
        display: flex;
        justify-content: space-between;
    }

    .content-schedule-text h2 {
        font-size: 38px;
    }

    .content-schedule-text .brief-description {
        font-size: 16px;
        margin-top: 40px;
    }
    
    .schedule-items-container-carousel-container {
        display: flex;
        justify-content: flex-end;
    }

    .schedule-items-container-carousel {
        display: flex;
        width: 80px;
        top: 160px;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    .schedule-items-container-carousel:hover {
        transform: scale(1.25);
        
    }

    .schedule-items-container-carousel > img {
        width: 64px;
        height: 64px;
        filter: invert(80%);
    }

    .schedule-header {
        height: 400px;
    }

    .schedule-title {
        font-size: 64px;
        line-height: 70px;
        margin-bottom: 0px;
    }

    .schedule-description-container {
        width: 600px;
        font-size: 16px;
    }
    
    .schedule-description-container  li {
        margin-left: 28px;
        margin-top: 4px;
    }
    .schedule-description-postscriptum {
        font-size: 12px;
    }

    .buttons-control-schedule {
        flex-direction: row;
      }

    .squares-image{
        width: 48%;
        background-size: cover;
    }
}

@media screen and (min-width: 52em) {

    .schedule-items-container {
        padding-inline: 0;
        width: auto;
        justify-content: center;
        padding-bottom: 10px;
    }

    .schedule-header {
        height: 480px;
    }

    .schedule-title {
        font-size: 70px;
        line-height: 87px;
    }

    .content-schedule-text h2 {
        padding: 0;
        margin-bottom: 64px;
        font-size: 34px;
        line-height: 39px;
    }

    .content-schedule-text {
        width: 85%;
    }

    .content-schedule-text .brief-description {
        font-size: 18px;
    }

    .schedule-description-container {
        width: 54vw;
    }

    .squares-image{
        width: 49%;
        background-size: contain
    }
}

@media screen and (min-width: 64em) {

    .content-schedule-info ul{
        width: 30%;
    }

    .content-schedule-text {
        width: auto;
        margin-bottom: 550px;
    }
    
    .content-schedule-text h2 {
        width: calc(454px + 30vw);
        margin-bottom: 32px;
        font-size: 44px;
    }

    .content-schedule-text .brief-description {
        width: calc(454px + 30vw);
        margin-top: 65px;
        margin-bottom: 48px;
    }

    .schedule-header {
        height: 400px;
    }

    .squares-image {
        width: 50%;
        background-size: cover;
        padding: 110px 0;
    }
}

@media screen and (min-width: 80em) {
    .schedule-items-container {
        width: 100%;
    }

    .schedule-description-container {
        width: 769px;
    }
}
