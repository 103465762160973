.confirm-message-container{
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.status-container {
    border: 2px solid #FDF8F5;
    padding-block: 8px;
    padding-inline: 12px 8px;
    border-radius: 11.421px;
    height: 28vh;
    width: 70vh;
    overflow: hidden;
    margin-top: -9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    text-wrap: wrap;
}

