.Schedule {
    background-color: black;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    padding-bottom: 5rem;
    min-height: 100vh;
}

.Schedule::after {
    content: "";
    display: table;
    clear: both;
}

.Schedule-title{
    color: #FDF8F5;
    padding-top: 1%;
    padding-bottom: 15px
}

.container-copyright-schedule{
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FDF8F5;
    position: relative;
    
    background: linear-gradient(to right, #EBFF08, #F102AE);
    color: #FDF8F5;
}


@media screen and (max-width: 400px){
    .container-copyright-schedule{
        font-size: 13px;
        padding-left: 10px;
    }
}