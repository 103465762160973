.container-copyright {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FDF8F5;
    position: relative;
    z-index: 10;
    margin-top: -3rem;
    background: rgba(17, 10, 37, 0.70);
}

.container-copyright p {
    font-size: .8rem;
}

@media (max-width: 426px) {
    .container-copyright {
        padding: 1rem;
        margin-top: -5rem
    }
    .container-copyright p {
        text-align: center;
    }
}