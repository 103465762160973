
.main-container{
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-container *{
        box-sizing: border-box;
}

/* Classe padrão */
.img-login {
    display: block; /* Garante que a imagem apareça em telas maiores */
  }
  
  /* Esconde a imagem em dispositivos móveis (largura menor que 768px, ajuste conforme necessário) */
  @media (max-width: 768px) {
    .main-container {
      flex-direction: column;
    }
    

    .LoginForm {
        width: 90% !important;
      }

  }
  

.custom-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .custom-checkbox input {
    appearance: none; /* Remove os estilos padrão do navegador */
    -webkit-appearance: none; /* Compatibilidade com WebKit */
    width: 12px !important; /* Largura do checkbox */
    height: 12px !important; /* Altura do checkbox */
    border: 2px solid #ccc; /* Borda inicial */
    border-radius: 100% !important; /* Faz com que seja redondo */
    background: transparent; /* Fundo transparente */
    outline: none; /* Remove o contorno ao focar */
    cursor: pointer;
    display: inline-block;
    padding: 6px 6px !important;
    vertical-align: middle;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    margin-right: 6px; /* Espaço entre o círculo e o texto */
  }
  
  .custom-checkbox input:checked {
    background: #ebff08; /* Fundo amarelo ao ser marcado */

  }
  
  .custom-checkbox span {
    font-size: 14px; /* Tamanho do texto */
    color: #333; /* Cor do texto */
    line-height: 1.5;
  }
  

.LoginForm {

    padding-left: 100px;
   
    display: inline-block;
    width: auto; /* Isso fará com que o container ocupe apenas o espaço do conteúdo */
    height: auto;
    max-width: 600px; 
}

.LoginForm label{
    font-weight: lighter;
    color: #ffffff;
    font-size: 20px;
    font-family: Inter, serif;
}

.img-login{
    display: inline-block;
    width: 300px; 
    height: 300px; 
    margin-top: 5%;
    margin-left: 10%;

    overflow: hidden;
}

.img-login img{
    max-width: 100%; 
    max-height: 100%; 
    width: auto; 
    height: auto;
}

.img-login div{
    width: 300px; 
    height: 300px;
    display: inline-block;
}


.inputs-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 8px;
}

.inputs-container  label {
    font-family: Inter, serif;
    color: #ffffff;
    font-size: 10px;
    margin-bottom: 8px;
    padding-left: 10px;
    width: 100%;
    font-weight: lighter;
}

.inputs-container input {
    width: 100%;
}


.texto-container{
    color: #ffffff;
    font-family: Inter, serif;
    font-size: 15px;
    text-align: center;
    font-weight: bolder;
}
@media screen and (min-width: 60em) {
.texto-container {
    margin-top: 2.5rem;
}

.containerFinal{

        margin-top: 2.5rem;
    
}
}


.containerFinal{
    display: flex;
    margin-left: auto;
    justify-content: space-between;
    
}

.containerFinal label{
    font-size: 13px;
    
}


.divLembra{
    display: inline-block;
    text-align: center;
    
        

}  

.containerFinal button{
    background-color: #EBFF08;
    color: #000000;
    border-radius: 10px;
    width: 100px;
    height: 25px;
    font-weight: bold;
    border-color:#EBFF08; ;
    font-size: 15px;
}










.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
   
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: black;
}



.LoginForm h1{
    
    text-align: center;
    white-space: nowrap;
    margin-bottom: 8px;
    margin-top: 0;
    color: #ffffff;
    font-weight: bold;
    font-size: 40px;
}

.LoginForm h2{
    
    font-size: 15px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
    color: #ffffff;
    font-weight: lighter;

}

.LoginForm input{
    background-color: black;
    border-color: #ffffff;
    border-radius: 5px;
    color: #ffffff;
   
}


.Loginform-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Loginform-loading > p {
    color: #FDF8F5;
    background: #460FE1;
    padding-block: 4px;
    padding-inline: 8px;
    border-radius: 8px;
}

.titulo {
    width: 100%;
    text-align: center;
    color: #460FE1;
    font-family: Inter, serif;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin: 0;
}

.titulo h1{
    width: 100%;
    text-align: center;
    white-space: nowrap;
    margin-bottom: 8px;
    margin-top: 0;
}

.titulo h2 {
    width: 100%;
    text-align: center;
    margin-top: 0;
    margin-bottom: 20px;
}


.filled-button {
    background-color: #F102AE;
    color: #ffffff;
    padding: 6px 24px;
    border: none;
    border-radius: 20px;
    font-size: 20px;
    font-weight: 700;
    width: 120px;
}

.button-container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.buttons-container {
    display: flex;
    justify-content: space-around;
}

#subscribe-redirect {
    text-decoration: underline;
    margin-top: 4px;

}

#subscribe-redirect:hover {
    text-decoration: underline;
    color: #460FE1;
    margin-top: 4px;

}

.loginContainer span {
    color: #F102AE;
    font-size: 18px;
    margin-left: 10px;
    font-family: Inter, serif;
    margin-top: 2px;
}

#subscribe-redirect:hover {
    color: #460FE1;
    margin-top: 0;
}




@media screen and (max-width: 640px) {

    .img-login {
        padding-top: 50px;

        margin: 0 auto; 
        display: flex; 
        justify-content: center;
    }
    
    .LoginForm {
        padding-left: 0px;
        padding-top: 50px;
        padding-bottom: 50px;
        margin: 0 auto; 
        display: flex; 
        justify-content: center;
    }

    .inputs-container input {
        width: 100%;
        
    }

    /*
    .Loginform {
        padding: 18px 28px;
        width: 85%;
        max-width: 500px;
    }
    */

    .titulo > h1 {
        font-size: 45px;
        font-weight: 700;
        margin-bottom: 0;
    }

    .titulo > h2 {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 15px;
    }

    .inputs-container > label {
        font-size: 18px;
        font-weight: bold;
    }

    .inputs-container.last {
        margin-bottom: 32px;
    }

    .buttons-container {
        margin-bottom: 8px;
        font-weight: bold;
        align-items: center;
        justify-content: space-between;
    }

    #subscribe-redirect {
        margin-top: 0;
        font-size: 20px;
    }
}

@media screen and (min-width: 640px) {
    .Loginform {
        padding: 24px 54px;
        width: 85%;
       
        max-width: 500px;
    }

    .titulo > h1 {
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 0;
    }

    .titulo > h2 {
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 15px;
    }

    .inputs-container > label {
        font-size: 20px;
        font-weight: bold;
    }

    .inputs-container.last {
        margin-bottom: 45px;
    }

    .buttons-container {
        margin-bottom: 12px;
        font-weight: bold;
        align-items: center;
        justify-content: space-between;
    }

    #subscribe-redirect {
        margin-top: 0;
        font-size: 20px;
    }
}

.login-error {
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
    color: #FB0000;
    font-weight: bold;
    padding: 10px 0;
}

