.sp-container {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.sp-section {
    margin-bottom: 72px;
}

.sp-section h3 {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    margin-bottom: 16px;
    text-align: center;
}

.sp-section-logos {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 16px;
    column-gap: 15px; /* Reduziu de 25px para 15px */
    row-gap:100px; /* Reduziu de 32px para 24px */
}

.sp-section-logos a:first-child {
    grid-column: span 4; /* Reduziu de 5 para 4 */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 110%; /* Reduziu de 130% para 110% */
    height: auto;
}

.sp-section-logos :is(svg, img) {
    max-width: 160px; /* Reduziu de 220px para 160px */
    width: 100%;
    max-height: 60px; /* Reduziu de 80px para 60px */
}

.sp-section-logos a:first-child :is(svg, img) {
    max-width: 100%; /* Reduziu de 300% para 200% */
    width: auto;
}

.sp-section-logos.first-section :is(svg, img) {
    max-width: 160px; /* Reduziu de 220px para 160px */
    width: 100%;
    max-height: 60px; /* Reduziu de 80px para 60px */
}

.sp-section-logos.first-section .sp-section-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.sp-section-empty-logo {
    width: 100%;
    height: 46px;
    line-height: 46px;
    font-size: 12px;
    border-radius: 8px;
    background: #D9D9D9;
    text-align: center;
    margin: auto;
}

.sp-section-logo {
    text-align: center;
}

@media screen and (min-width: 40em) {
    .sp-container {
        margin-top: 140px;
    }

    #titulo-apoio{
        margin-top: -10;
    }

    .sp-section h3 {
        text-align: initial;
    }

    .sp-section-logos {
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 32px;
        row-gap: 70px;
    }

    .sp-section-logos :is(svg, img) {
        max-width: 120px;
    }
}

@media screen and (min-width: 52em) {
    .sp-section-logos {
        column-gap: 48px;
        row-gap: 32px;
    }

    .sp-section-logos svg {
        max-width: 160px;
    }
}

@media screen and (min-width: 64em) {
    .sp-container {
        margin-top: 100px;
    }

    .sp-section h3 {
        margin-bottom: 32px;
    }

    .sp-section-logos {
        display: grid;
        grid-template-columns: repeat(5, 1fr); 
        column-gap: 25px; 
        row-gap: 32px;     
    }
    
    .sp-section-logos a:first-child {
        grid-column: span 5; 
        display: flex; 
        justify-content: flex-start; 
        align-items: flex-start; 
        width: 130%;
        height: auto; 
    }
    
    .sp-section-logos :is(svg, img) {
        max-width: 220px;
        width: 100%;
        max-height: 80px;
    }

    #titulo-apoio{
        margin-top: -74px;
    }

    .sp-section-logos a:first-child :is(svg, img) {
        max-width: 300%; 
        width: auto;       
    }

    .sp-section-logos.first-section :is(svg, img) {
        max-width: 220px;
        width: 100%;
        max-height: 80px;
    }
    .sp-section-logos.first-section .sp-section-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%; 
    }
}