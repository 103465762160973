.container-sectionHeader {
    background: black;
    color: #D9D9D9;
}

.container-sectionHeader {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    z-index: 10;
}

.content-sectionHeader {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-bottom: 2rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 10;
}

.sectionHeader-title {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    text-align: center;
    width: 100%;
}

.content-sectionHeader .sectionHeader-title h1 {
    font-size: 32px;
    width: 100%;
    margin-block: 2rem;
}

.button-header {
    padding: .7rem 1.2rem;
    color: #D9D9D9;
    font-weight: 700;
    border-radius: 25px;
}

.button-inscreva-se {
    border: 2px solid #F102AE;
    background-color: #F102AE;
    transition: 0.3s;
}

.button-inscreva-se:disabled {
    background-color: #cecece;
    border: 2px solid #cecece;
    color: #000000;
    opacity: 0.5;
}

.button-inscreva-se:disabled:hover {
    background-color: #cecece;
    border: 2px solid #cecece;
    color: #000000;
}

.button-inscreva-se:hover {
    background-color: #FDF8F5;
    border: 2px solid #000000;
    color: #F102AE;
}

.button-inscreva-se:active {
    background-color: #cccccc;
    border: 2px solid #cccccc;
}

.button-programacao {
    background-color: transparent;
    border: 2px solid #D9D9D9;
    transition: 0.3s;
}

.button-programacao:hover {
    background-color: #2ACEB1;
    border: 2px solid #000000;
    color: #FDF8F5;
}

.button-programacao:active {
    background-color: #3d3d3d;
}

.downscroll {
    display: flex;
    width: 100%;
    height: 2rem;
    padding: 1rem 0;
    justify-content: center;
}

.buttonScroll {
    cursor: pointer;
}

.buttons-control-header {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 2rem;
}

.container-sectionHeader header {
    flex-direction: column;
    height: 15vh;
}

.container-sectionHeader header .container-options ul{
    padding: 0;
    margin-block: 2rem;
    font-size: 14px;
    gap: 16px
}

.sectionHeader-headerImg {
    width: 80%;
    padding-bottom: 40px;

}

.squares-image-left, .squares-image-right{
    display: none;
}


@media screen and (min-width: 40em) {
    .container-sectionHeader {
        padding: 2rem 7rem;
    }

    .content-sectionHeader .sectionHeader-title {
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 3rem;
    }

    .content-sectionHeader .sectionHeader-title h1 {
        font-size: 38px;
    }

    .content-sectionHeader .sectionHeader-title .button-inscreva-se {
        margin: 1rem;
    }

    .container-sectionHeader header .container-options ul{
        font-size: 18px;
        gap: 60px
    }

    .sectionHeader-headerImg {
        height: 19rem;
        width: auto;
    }
}

@media screen and (min-width: 52em) {
    .container-sectionHeader {
        padding: 2rem 5rem;
    }

    .content-sectionHeader {
        flex-direction: row;
        height: 60vh;
    }

    .container-sectionHeader header {
        flex-direction: row;
        height: 10vh;
    }

    .content-sectionHeader .sectionHeader-title {
        text-align: initial;
        align-items: initial;
        width: 40%;
    }

    .content-sectionHeader .sectionHeader-title h1 {
        font-size: 36px;
        width: 100%;
    }

    .container-sectionHeader header .container-options ul{
        font-size: 16px;
        gap: 5rem;
    }

    .content-sectionHeader .sectionHeader-title .button-inscreva-se {
        margin: 0;
    }

    .squares-image-right{
        display: flex;
        padding-bottom: 15rem;
        background-size: contain;
        margin-top: -390px;
        width: 600px; 
        height: 200px;
        position: absolute;
        top: 50%;
        transform: translateY(22%);
        right: -100px; 
    }

    .footer-squares {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .squares-image-left {
        display: flex;
        padding-bottom: 15rem;
        background-size: contain;
        margin-top: -390px;
        width: 421px;
        height: 202px;
        position: absolute;
        top: 32px;
        transform: translateY(22%);
        left: -85px;
    }
    

    .downscroll {
        margin-top: -40px;
    }
}

@media screen and (min-width: 64em) {
    .container-sectionHeader {
        padding: 2rem 9rem;
    }
    .content-sectionHeader .sectionHeader-title h1 {
        font-size: 46px;
    }
}