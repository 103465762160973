.schedule-section-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.schedule-section-tab {
    background: #F102AE;
    border-radius: 20px 0px;
    width: 80%;
}



.schedule-section-controls{
    position: relative;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 10px;
    gap: 16px;
}

.schedule-section-controls::before,
.schedule-section-controls::after  {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #BBBBBB ;
}

.schedule-section-controls::before{
    top: 0;
}

.schedule-section-controls::after{
    bottom: 0;
}

.search-input{
    width: 90%;
    background-color: black;
    border-color: #FFFFFF;
    border-radius:  3.999px;
    padding: 8px 12px 8px 35px;
    background-image: url("../../../assets/lupa.svg"); 
    background-repeat: no-repeat;
    background-position: 13px center; 
    background-size: 15px 15px; 
    color:#FDF8F5;
}

.filter-group {
    display: flex;
    flex-direction: column;
}

.filter-checkbox {
    margin: 4px 0;
    color: white;
}

.schedule-section-tab-title {
    padding-block: 6px 12px;
    padding-left: 16px;
    font-size: 18px;
    font-weight: 800;
    line-height: 33px;
    color: #FDF8F5;
}

.schedule-section-tab-days {
    font-size: 12px;
    font-weight: 800;
    line-height: 16px;
    display: flex;
    justify-content: center;
    margin-top: 12px;
}

.schedule-section-day {
    color: #AC9B9B;
    margin-right: 8px;
}

.schedule-section-day-highlight {
    width: 100%;
    height: 3px;
    margin-top: 2px;
    border-radius: 8px;
    background-color: #EBFF08;
}

.day-option {
    cursor: pointer;
}

.schedule-section-day-active {
    color: #F102AE;
    margin-right: 8px;
    cursor: pointer;
}

.schedule-section-grid {
    display: grid;
    margin-top: 50px;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(165px, auto));
    justify-content: space-evenly;
    justify-items: center;
}

.error-message {
    color: #F102AE;
    font-weight: 300;
    font-size: 12px;
    padding-inline: 12px;
}

.empty-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 300;
}

.empty-schedule {
    color: #FDF8F5;
    font-size: 12px;
    padding-inline: 12px;
}

@media screen and (min-width: 40em) {
    .schedule-section-container {
        width: 540px;
        align-items: normal;
    }

    .schedule-section-tab {
        width: 100%;
    }

    .schedule-section-tab-title {
        font-size: 27px;
    }

    .schedule-section-tab-days {
        font-size: 18px;
        line-height: 20px;
        justify-content: flex-end;
    }

    .schedule-section-grid {
        grid-template-columns: repeat(auto-fit, minmax(225px, auto));
        justify-content: space-evenly;
    }
}

@media screen and (min-width: 52em) {
    .schedule-section-container {
        width: 54vw;
        align-items: normal;
    }

    .schedule-section-grid {
        grid-template-columns: repeat(auto-fit, minmax(225px, auto));
        justify-content: space-evenly;
    }

    .error-message {
        font-size: 16px;
        padding: 0;
    }

    .empty-box {
        margin-top: 64px;
    }

    .empty-schedule {
        font-size: 16px;
    }
}

@media screen and (min-width: 64em) {
    .schedule-section-grid {
        grid-template-columns: repeat(auto-fit, minmax(350px, auto));
        justify-content: space-evenly;
    }
    
    .schedule-section-day:hover {
        color: #EBFF08;
    }

    .schedule-section-day-indent {
        color:#AC9B9B !important;
    }
}

@media screen and (min-width: 80em) {
    .schedule-section-container {
        width: 960px;
    }
}

@media screen and (min-width: 120em) {
    
}
