.image-picker-container {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.image-picker-container-exclude {
  position: absolute;
  height: 100%;
  width: 200px;
  top: 0;
  cursor: pointer;
}

.exclude-box {
  position: absolute;
  right: 0;
  transition: 0.3s ease-out;
}

.exclude-box > svg {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

.image-picker-container-exclude:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.image-picker-container-exclude:hover > div {
  transform: translate(-88px, 50%);
}

.image-picker-container-exclude:hover > div > svg {
  background: none;
  color: white;
}
