/* Fundo escurecido quando o modal está aberto */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 20px;
    width: 300px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    font-family: Arial, sans-serif;
}

.close-modal-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.open-modal-btn {
    padding: 10px 20px;
    font-size: 16px;
    background: transparent;
    cursor: pointer;
    display: flex;
    width: 45.92px;
    padding: 24px 12px 8px 35px;
    justify-content: center;
    align-items: center;
    border: 1.333px solid #EBFF08;
    border-radius: 4px;
    background-image: url("../../../../assets/filter.svg"); 
    background-repeat: no-repeat;
    background-position: center; 
    background-size: 30px 30px; 
}

.filter-group h3 {
    margin-bottom: 10px;
    font-weight: bold;
}

.filter-option {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 16px;
    color: #333;
}

.filter-option input {
    margin-right: 10px;
}
