.embla {
    max-width: 48rem;
    margin: auto;
    --slide-height: 19rem;
    --slide-spacing: 1rem;
    --slide-size: 65%;
  }
  .embra__viewport {
    overflow: hidden;
  }
  .embra__container {
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
    gap: 40px;
    width: fit-content;
    padding-top: 70px;
    justify-content: center;
  }
  .embla__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
  }

  .schedule-carousel-grid {
    padding-left: 2%;
    overflow-x: hidden;
    padding-top: 4%;
  }

  .schedule-items-container-carousel{
      display: flex;
      width: 80px;
      top: 120px;
      height: 100%;
      align-items: center;
      justify-content: center;
  }



