.alert-container {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(187, 187, 187, 0.5);
    top: 0;
    box-sizing: border-box;
    padding-inline: 8px;
    z-index: 20;
}

.alert-card {
    width: 400px;
    height: auto;
    box-sizing: border-box;
    border-radius: 8px;
    background: #FDF8F5;
    padding: 30px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
}

.alert-title {
    margin: 0;
}

.alert-action {
    color: rgba(251, 0, 0, 1);
    font-weight: 600;
}

.alert-button {
    width: 100px;
    padding: .7rem 1.2rem;
    color: #FDF8F5;
    font-weight: 700;
    border-radius: 25px;
    transition: 0.3s;
    border: 1px solid #FDF8F5;
    margin-right: 1rem;
}

.alert-confirm {
    background-color: #2ACEB1;
}

.alert-confirm:hover {
    background-color: #2ACEB1;
    border: 1px solid #2ACEB1;
}

.alert-close {
    background-color: rgb(251, 0, 0);
    opacity: 0.4;
}

.alert-close:hover {
    opacity: 1;
    border: 1px solid rgb(251, 0, 0);
}