@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

* {
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Sora', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FDF8F5;
}

html,
body {
  max-width: 100vw;
  max-height: 100vh;
  overflow-x: hidden;
}

/* body > #root > div {
  min-height: 100vh;
} */


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.relative-box {
  position: relative;
}

.body-content {
  min-height: calc(100vh - 10vh - 48px);
}