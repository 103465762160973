header {
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: space-between;; 
}


.container-logo {
    height: 100%;
    display: flex;
    align-items: center;
}

.container-options ul {
    display: flex;
    gap: 4rem; 
    font-weight: 700;
}

.container-options li {
    transition: 0.3s;
}

.container-options .header-active-link:hover {
    color: #ccc;
}

.container-options .header-active-link:active {
    color: #f37dd2;
}

@media screen and (max-width: 350px) {
    .container-options li {
        font-size: 11px;
    }
}
    