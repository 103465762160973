.App {
    width: 100%;
    height: 100vh;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

li {
    list-style: none;
}

button {
    cursor: pointer;
}