.manualCheckinContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-block: 24px;
    width: 100%;
    height: 90vh;
    min-height: 90vh;
    background-color: rgb(30 30 30);
}

/* style.css */

.qr-reader-wrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7); /* Fundo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.qr-reader-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;
}

.qr-reader {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #ff4d4d;
  border: none;
  color: white;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  opacity: 0.9;
  transition: opacity 0.3s;
  z-index: 10000;
}

.close-button:hover {
  opacity: 1;
}


.doCheckin {
    width: 50%;
    border: 1px solid #FDF8F5;
    border-radius: 14px;
    box-sizing: border-box;
    padding: 12px;
}

.doCheckin > h1{
    color: #FDF8F5;
    font-family: Inter, serif;
    font-weight: bold;
    margin-top: 0;
}

.formContainer{
    padding: 20px;
}

.formContainer span {
    color: #F102AE;
    font-size: 11px;
    margin-left: 10px;
    margin-top: 2px;
}

.pTitle {
    color: #EBFF08;
}

@media (max-width: 768px) {
    h1 {
        font-size: 24px;
    }
    #id-label {
        font-size: 14px;
    }
    .button-container > button {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    h1 {
        font-size: 18px;
    }
    #id-label {
        font-size: 12px;
    }
    .button-container > button {
        font-size: 10px;
    }
}

@media (max-width: 375px) {
    h1 {
        font-size: 12px;
    }
    #id-label {
        font-size: 8px;
    }
    .button-container > button {
        font-size: 8px;
    }
}